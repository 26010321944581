
import { createTag, Tag } from 'momai'
import { Toast } from 'vant'
import { defineComponent, PropType, ref, watch } from 'vue'

export default defineComponent({
  props: {
    tag: {
      type: Object as PropType<Tag>,
      required: true
    },
    tagTypeEditable: {
      type: Boolean,
      default: true
    }
  },

  setup: (props, { emit }) => {
    const tagForm = ref<Tag>(createTag(props.tag))

    watch(() => props.tag, newTag => {
      tagForm.value = newTag
    }, {
      deep: true
    })

    watch(tagForm, newTagForm => {
      emit('update:tag', newTagForm)
    }, {
      deep: true
    })

    return {
      tagForm,
      submit: () => {
        if (!tagForm.value.tagName) {
          Toast.fail('请输入名称！')
          return false
        }
        return true
      }
    }
  }
})
